@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --page-background: rgb(4, 12, 26);
  --primary-color: rgb(243, 243, 243);
  --secondary-color: rgb(255, 205, 5);
  --third-color: rgb(14, 14, 14);
  --just-white: #fbfbfd;
  --light-font: rgb(90, 90, 90, 1);
  --success-color: rgba(131, 255, 175, 0.945);
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  min-width: 320px;
}

body {
  font-family: 'DM Sans', sans-serif;
  display: flex;
  flex-direction: column;
}

h1 { 
  font-size: 3rem;
  line-height: 3.2rem;
}

h2 {
  font-size: 2.2rem; 
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

h3 {
  font-size: 2rem;
}

p {
  font-size: 1.6rem;
}

a:hover {
  color: none;
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--secondary-color);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	select:focus,
	textarea:focus,
	input:focus {
		font-size: 16px;
	}
}

@media only screen and (min-width: 767px) {
  h1 { 
    font-size: 4.2rem;
    line-height: 4.8rem;
  }
  
  h2 {
    font-size: 2.6rem; 
    font-weight: bold;
  }
  
  h3 {
    font-size: 2.4rem;
  }
  
  p {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  h1 { 
    font-size: 5rem;
    line-height: 4.4rem;
  }
  
  h2 {
    font-size: 3.8rem;
    font-weight: bold;
  }
  
  h3 {
    font-size: 2rem;
  }

  p {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1439px) {
  h1 { 
    font-size: 6rem;
    line-height: 7rem;
  }
  
  h2 {
    font-size: 4rem; 
    font-weight: bold;
  }
  
  h3 {
    font-size: 2.4rem;
  }

  p {
    font-size: 3rem;
  }
}
