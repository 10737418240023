.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0 25px 0;
  background-color: var(--third-color);
  text-align: center;
}

.footer-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer-text {
  padding: 0 30px;
  text-align: center;
  color: var(--primary-color);
  font-weight: 400;
}

.social-networks {
  min-width: 300px;
  margin-top: 20px;
  padding:15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.network-icon {
  color: var(--secondary-color);
}

@media only screen and (min-width: 767px) {
  .Footer {
    padding: 40px;
  }
  .network-icon {
    font-size: 1.4rem;
    margin: 20px;
  }
  .footer-text {
    font-size: 3.2rem;
    max-width: 900px;
  }
}

@media only screen and (min-width: 1023px) {
  .footer-text {
    margin: 20px;
  }
}