.EntryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 10px;
  width: 100%;
  max-width: 1024px;
}

.my-entry {
  min-height: 100vh;
  max-width: 85vw;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
}

.entry-title {
  margin-bottom: 10px;
}

.entry-img {
  max-width: 80vw;
  border-radius: 5px;
}

.entry-opening {
  color: var(--light-font);
  font-style: italic;
  font-size: 1.6rem;
  margin: 15px 0 0 0;
  max-width: 80vw;
}

.markdown-container {
  max-width: 80vw;
}

.markdown-container p {
  margin: 17px 0;
}

strong {
  font-size: 2rem;
}

.entry-sub-header{
  max-width: 80vw;
  margin-bottom: 20px;
  font-size: 2.4rem;
  line-height:  2.8rem;
  font-weight: bold;
  overflow-wrap: break-word;
}

.entry-content {
  margin-bottom: 25px;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-align: justify;
}

.articles-section-title{
  font-size: 2.6rem;
  align-self: center;
  margin: 30px 0;
}

@media only screen and (min-width: 767px) {
  .entry-title {
    font-size: 3.8rem;
    margin-bottom: 20px;
  }

  .entry-opening {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .entry-sub-header {
    font-size: 2.8rem;
    line-height: 3.8rem;
  }
  
  .entry-content {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .EntryContainer {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    padding: 45px;
    padding-bottom: 10px;
  }
  
  .my-entry {
    max-width: 70vw;
  }
  
  .entry-title {
    margin-bottom: 30px;
  }
  
  .entry-img {
    max-width: 65vw;
    border-radius: 15px;
  }

  .entry-opening {
    max-width: 62vw;
  }
  
  .markdown-container {
    max-width: 64vw;
  }

}

@media only screen and (min-width: 1350px) {
  .my-entry {
    max-width: 52vw;
  }
  
  .entry-img {
    max-width: 50vw;
  }

  .markdown-container {
    max-width: 45vw;
  }

  .entry-opening {
    max-width: 51vw;
  }
}
