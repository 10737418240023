.Services {
  padding: 30px 25px 0;
}

.service {
  margin-bottom: 30px;
}


.service-name {
  margin-bottom: 10px;
}

.service-image--container {
  margin: 15px 0;
}

.service-image {
  max-width: 80vw;
  min-height: 100px;
  border-radius: 7px;
}

@media only screen and (min-width: 767px) {
  .Services {
    padding: 50px 30px;
    margin-left: 20px;
    margin-bottom: 30px;
    columns: 2 auto;
    column-gap: 60px;
    break-after: avoid;
    break-before: avoid;
    break-inside: avoid;
    vertical-align: top;
  }

  .service {
    min-width: 325px;
  }

  .service-image {
    margin: 15px 0 5px;
    max-width: 300px;
  }
}

@media only screen and (min-width: 1024px) {
  .Services {
    margin-bottom: 0;
    padding: 75px 100px;
    margin-left: 30px;
    padding-bottom: 40px;
    min-width: 1000px;
    max-width: none;
    column-gap: 115px;
  }

  .service {
    min-width: 330px;
    max-width:  320px;
    margin-bottom: 50px;
  }

  .service-image {
    margin: 15px 0 5px;
    max-width: 330px;
  }

  .service-image--container {
    margin: 0;
  }
}