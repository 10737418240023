.Header {
  z-index: 1;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-color);
  padding: 0 15px;
  position: fixed;
}

.logo-container {
  grid-column: 1;
  justify-self: right;
  color: rgb(33,37,41);
  width: 150px;
}

.logo-image {
  width: 150px;
}


@media only screen and (min-width: 767px) {
  .Header {
    height: 100px;
    padding: 0 30px;
  } 
  .logo-image {
    width:180px;
  }
}

@media only screen and (min-width: 1024px) {
  .Header {
    height: 100px;
    display: flex;
    justify-content: center;
  }

  .logo-image {
    width: 150px;
  }

  .logo-container {
    width: 3000px;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 1439px) {
  .Header {
    height: 130px;
  }
  .logo-image {
    width: 250px;
  }
}