@media only screen and (min-width: 767px) {
  .entries-list {
    padding: 0 10px;
    position: relative;
    columns: 3 auto;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1023px) {
  .entries-list {
    padding-left: 20px;
    min-width: 980px;
    columns: 3 auto;
    margin-bottom: 30px;
  }
}
