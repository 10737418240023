.success-button {
  color: var(--third-color);
}

.LeadForm {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65vw;
  max-width: 800px;
}

.LeadForm label {
  font-size: 2rem;
  color: var(--just-white);
  width: 100%;
  text-align: center  ;
}

.LeadForm input {
  margin: 10px 0;
  padding: 10px;
  font-size: 1.8rem;
  width: 100%;
  height: 50px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
}

.LeadForm button {
  margin: 20px;
  margin-bottom: 0;
  padding: 9px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--secondary-color);
  box-shadow: 2px 2px 10px -3px var(--third-color);
  font-size: 1.8rem;
}

@media only screen and (min-width: 767px) {
  .LeadForm {
    margin: 0;
  }
  .LeadForm label {
    font-size: 2.5rem;
  }
  .LeadForm button {
    font-size: 2.5rem;
    padding: 10px 50px;
  }
  .LeadForm input {
    height: 50px;
    margin: 25px 0;
  }
}

@media only screen and (min-width: 1024px) {

  .LeadForm {
    width: 25vw;
  }

  .LeadForm input {
    font-size: 2.4rem;
    height: 60px;
  }
  .LeadForm button {
    font-size: 2.4rem;
  }
}


@media only screen and (min-width: 1439px) {
  .LeadForm {
    padding: 0 200px;
  }
}
