.PresentationCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 30px 0;
  margin-top: 60px;
  padding: 10px 20px;
  background-color: var(--just-white);
  scroll-snap-align: center;
  min-width: 190px;
  max-width: 300px;
  height: 100%;
  border: none;
  border-radius: 15px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
}

.portait-container {
  height: 40px;
}

.portait {
  width: 100px;
  position: relative;
  top: -60px;
}

.name {
  margin-top: 20px;
  margin-bottom: 12px;
}

.caption {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 15px;
}

.phone {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.mail {
  text-decoration: underline;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  margin-bottom: 10px;
}


@media only screen and (min-width: 767px) {
  .PresentationCard {
    max-width: 370px;
    padding: 0;
    margin-top: 85px;
  }
  .portait {
    width: 130px;
    top: -85px;
  }
  .name {
    font-size: 3rem;
  }
  .caption {
    font-size: 2rem;
    margin: 15px 0;
  }
  .phone {
    font-size: 2.4rem;
    margin: 15px 0;
  }
  .mail {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

@media only screen and (min-width: 1024px) {
  .PresentationCard {
    max-width: 450px;
    width: 450px;
    padding: 30px 50px;
    margin-top: 70px;
  }
  .portait {
    width: 150px;
    top: -100px;
  }
  .name {
    font-size: 2.8rem;
    padding-top: 10px;
    margin-bottom: 10px;
  }
  .caption {
    font-size: 2rem;
    font-weight: 100;
    margin: 0;
  }
  .phone {
    font-size: 2.4rem;
    margin: 10px 0;
  }
  .mail {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 2.8rem;
  }
}