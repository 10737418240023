.CardTitle {
  padding: 15px 0;
}


@media only screen and (min-width: 767px) {
  .CardTitle {
    font-size: 2.6rem;
  }
}

@media only screen and (min-width: 1024px) {
  .CardTitle {
    font-size: 2.8rem;
  }
}