.cards-carousel {
  width: 100%;
  padding-bottom: 30px;
  display: flex;  
  height: 100%;
  overflow-x: scroll;
  overscroll-behavior: auto;
  scroll-snap-type: x proximity;
}

.cards-carousel::-webkit-scrollbar, .cards-carousel::-webkit-scrollbar-thumb {
  display: none;
}

@media only screen and (min-width: 767px) {
  .cards-carousel {
    display: flex;
    padding: 30px 12px;
    padding-top: 10px;
    justify-items: center;
  }
}

@media only screen and (min-width: 1024px) {
  .cards-carousel {
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 0;
  }
}
