.button-link {
  color: var(--third-color);
  padding: 9px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--secondary-color);
  box-shadow: 2px 2px 10px -3px var(--third-color);
  font-size: 1.8rem;
}

@media only screen and (min-width: 767px) {
  .button-link {
    font-size: 2.4rem;
    padding: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .button-link {
    font-size: 2rem;
    margin-bottom: 10px;
  }   
}