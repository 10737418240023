.hero-container {
	padding: 20px 20px 30px;
	width: 100%;
	background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(103, 103, 103, 0.327111) 61.46%, rgba(62, 62, 62, 0.47178) 80.21%, rgba(14, 14, 14, 0.55) 100%);
}
.hero-image {
	width: 100%;
	min-width: 150px;
	max-height: 800px;
	object-fit: cover;
}
.text-container {
	display: block;
	background-color: Third Color;
}
.hero-title {
	text-align: left;
	font-weight: bolder;
}
.hero-subtitle, .changing-word {
	font-size: 2rem;
	font-weight: 400;
	margin: 20px 0;
}

.hero-subtitle--container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 20px 0;
}

.hero-subtitle--container p {
	margin: 0 0.3rem;
}



.fade-enter{
	opacity: 0;
}
.fade-exit{
	opacity: 1;
}
.fade-enter-active{
	opacity: 1;
}
.fade-exit-active{
	opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
	transition: opacity 500ms;
}

@media only screen and (min-width: 767px) {
	.hero-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px 20px 50px;
	}
	.text-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.hero-title, .hero-subtitle {
		text-align: center;
		max-width: 900px;
		align-self: center;
		margin: 20px;
	}
	.hero-subtitle, .changing-word {
		font-size: 2.2rem;
		line-height: 2.8rem;
		max-width: 450px;
		margin-bottom: 40px;
		margin-top: 0;
	}

	.hero-subtitle--container p {
		margin: 0 0.4rem;
	}

	.hero-buttons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 600px;
		margin: 25px 0 10px;
	}

}


@media only screen and (min-width: 1024px) {
	.hero-container {
		min-height: 400px;
		padding: 20px 20px 40px;
	}

	.hero-title {
		margin: 30px;
		margin-bottom: 30px;
		line-height: 6rem;
	}
	
	.hero-subtitle, .changing-word {
		font-size: 3rem;
		line-height: 4rem;
		max-width: 600px;
		margin-bottom: 50px;
	}

	.hero-subtitle--container p {
		margin: 0 0.5rem;
	}

	.text-container {
		margin: 15px;
	}

}

@media only screen and (min-width: 1439px) {
	.hero-title {
		margin: 60px;
		width: 90vw;
	}
}