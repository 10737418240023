.Newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: var(--third-color);
  padding: 20px;
  padding-bottom: 2px;
  max-width: 100%;
}

.newsletter-cta {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: var(--just-white);
}

@media only screen and (min-width: 768px) {
  .newsletter-cta {
    margin: 40px;
  }
}


@media only screen and (min-width: 1024px) {
  .newsletter-cta {
    margin: 50px;
    font-size: 2.8rem;
  }
}