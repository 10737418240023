.Card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px;
  background-color: var(--just-white);
  padding: 10px 20px;
  padding-top: 0;
  scroll-snap-align: center;
  min-width: 190px;
  max-width: 300px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
  color: black;
  text-decoration: none;
}

.Card-image--container {
  width: 100%;
  margin-bottom: 15px;
}

.Card-image {
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
}

@media only screen and (min-width: 767px) {
  .Card {
    min-width: 250px;
    max-width: 500px;
    padding: 20px;
    padding-top: 5px;
  }
  .Card-content {
    width: 200px;
  } 
}

@media only screen and (min-width: 1024px) {
  .Card {
    max-width: 270px;
    padding: 0 25px;
    padding-bottom: 30px;
    margin: 0px 15px 50px;
  }

  .Card-content {
    width: 220px;
  } 
}