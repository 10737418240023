.Card-button {
  color: var(--third-color);
  background-color: var(--secondary-color);
  padding: 8px 20px;
  margin: 10px 0;
  border: none;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 767px) {
  .Card-button {
    font-size: 2.2rem;
    margin: 30px 0;
    padding: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .Card-button {
    font-size: 2rem;
    margin: 0 auto;
    margin-top: 15px;
  }
}