.Home {
  width: 100%;
  max-width: 1024px;
}

.services-giant-image {
  display: none;
}

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  

}

@media only screen and (min-width: 1024px) {
  .Home {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
}
