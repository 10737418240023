.Blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  padding-bottom: 25px;
}

@media only screen and (min-width: 1024px) {
  .Blog {
    padding-bottom: 40px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
}