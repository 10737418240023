.Contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(103, 103, 103, 0.327111) 61.46%, rgba(62, 62, 62, 0.47178) 80.21%, rgba(14, 14, 14, 0.55) 100%);
}


@media only screen and (min-width: 767px) {
  .Contact {
    padding-bottom: 30px;
  } 
}
