.title-container {
  display: flex;
  justify-content: center;
}

.title, .title-white, .title-black {
  width: 88vw;
  margin: 25px 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-align: center;
}

.title-white {
  color: var(--just-white);
}

@media only screen and (min-width: 1024px) {
  .title {
    margin: 50px 0;
  }
}

@media only screen and (min-width: 1024px) {
  .title {
    font-size: 2.8rem;
  }
}