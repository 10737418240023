.entry-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  background-color: var(--just-white);
  padding: 10px 20px;
  padding-top: 0;
  scroll-snap-align: center;
  max-width: 300px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
  color: black;
  text-decoration: none;
  break-inside: avoid;
  /* break-after: avoid; */
  /* break-before: avoid; */
  vertical-align: top;
}

.entry-card-image--container {
  width: 100%;
  margin-bottom: 15px;
}

.entry-card-image {
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
}

.entry-card-description {
  margin-bottom: 5px;
}

@media only screen and (min-width: 767px) {
  .entry-card {
    padding: 20px;
    padding-top: 5px;
  }
  .entry-card-description {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .entry-card {
    max-width: 300px;
    padding: 0 25px;
    padding-bottom: 30px;
    margin: 0 0 40px 0;
  }
}