 .Layout {
  background-color: rgb(255, 255, 255);
 }
 
 .layout-content-container {
   padding-top: 85px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   align-items: center;
 }

 .whatsapp-container {
  position: fixed;
  right: 20px;
  bottom: 70px;
 }

 .whatsapp-container:hover {
  transform: scale(1.3);
 }

 @media only screen and (min-width: 767px) {
  .layout-content-container {
    padding-top: 100px;
  }
 }

 @media only screen and (min-width: 1024px) {
  .layout-content-container {
    padding-top: 100px;
  }
  .whatsapp-container {
    right: 40px;
  }
}

@media only screen and (min-width: 1439px) {
  .layout-content-container {
    padding-top: 130px;
  }
  .whatsapp-container {
    right: 60px;
  }
}